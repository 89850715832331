import { JyveSharedLibraryContext } from 'providers/JyveSharedLibraryContext';
import { useContext } from 'react';

export const useRollbar = () => {
  const { rollbar } = useContext(JyveSharedLibraryContext);
  if (!rollbar)
    throw new Error(
      'In order to use rollbar, you have to pass it into JyveSharedLibraryProvider!'
    );

  return rollbar;
};
