import React from 'react';
import { AxiosInstance } from 'axios';
import Rollbar from 'rollbar/dist/rollbar.umd.js';

export type ContextProps = {
  HTTP: AxiosInstance | null;
  rollbar?: Rollbar;
};

export type JyveSharedLibraryProviderProps = {
  HTTP: AxiosInstance | null;
  rollbarConfiguration?: Rollbar.Configuration | undefined;
};

export type JyveSharedLibraryProviderState = {
  rollbar: Rollbar | undefined;
};

const JyveSharedLibraryContext = React.createContext<ContextProps>({
  HTTP: null,
});

class JyveSharedLibraryProvider extends React.Component<
  JyveSharedLibraryProviderProps,
  JyveSharedLibraryProviderState
> {
  state = {
    rollbar: undefined,
  };

  constructor(props) {
    super(props);
    const { rollbarConfiguration } = props;
    if (rollbarConfiguration) {
      this.state.rollbar = new Rollbar({
        ...rollbarConfiguration,
        ...{
          captureUncaught: true,
          captureUnhandledRejections: true,
        },
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    const { rollbar } = this.state;
    if (rollbar) {
      rollbar.error(error, errorInfo);
    }
  }

  render() {
    const { HTTP, children } = this.props;
    const { rollbar } = this.state;
    return (
      <JyveSharedLibraryContext.Provider
        value={{
          HTTP,
          rollbar,
        }}
      >
        {children}
      </JyveSharedLibraryContext.Provider>
    );
  }
}

export { JyveSharedLibraryProvider, JyveSharedLibraryContext };
